import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React , { useEffect } from 'react'
import {Payers} from './Payers'
import {styles,CustomSelectInput,CustomInput} from './Style'
import { KeyboardArrowDown } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {simulate} from './Simulator'
import {currencies} from './CurrencyHelper'
import { Button } from "src/templates"

const ProntoPago = () => {
 const matches = useMediaQuery('(min-width:1024px)');
 const [simulation, setSimulation] = React.useState({
  days: 30,
  amount:{value:0,formatted:'$0'},
  payer:'',
 });

 const [financing, setFinancing] = React.useState({
  receivedAmount:{value:0,formatted:'$0'},
  cost:{value:0,formatted:'$0'},
 });

 const daysList = Array(30 - 1 + 1).fill().map((_, idx) => {
  let day = 1 + idx;
  return {id:day,name: day === 1 ? `${day} día` : `${day} días` }
 })

 const handlePayerChange = (event) =>{
  setSimulation({
   ...simulation,
   payer: event.target.value
  })
 };
 const handleDaysChange = (event) => {
  let newSimulation = {...simulation}
  newSimulation.days = event.target.value === '' ? '' : Number(event.target.value)
  setSimulation(simulation => ({
   ...newSimulation
  }))
 };

 const handleAmountChange = (event) => {
  let newSimulation = {...simulation}
  newSimulation.amount = currencies['CLP'](event.target.value)
  setSimulation(simulation => ({
   ...newSimulation
  }))
 };

 useEffect(
  () => {
   if (simulation.payer && simulation.days && simulation.amount.value ){
    simulate({payer:simulation.payer,days:simulation.days,amount:simulation.amount.value})
     .then(({monto_girar, costo_total})=>{
      setFinancing({
       receivedAmount: currencies['CLP'](monto_girar),
       cost: currencies['CLP'](costo_total),
      })
     })
   }
  },
  [simulation.payer,simulation.days,simulation.amount],
 );

 return (
  <Card sx={ styles.container(matches) }>
   <Box sx={ styles.itemInput }>
    <Typography variant="subtitle1" color="primary.dark" sx={styles.itemText} gutterBottom>
      Total de tu factura
    </Typography>
    <CustomInput
     id="invoiceAmount"
     value={simulation.amount.formatted}
     className={'invoiceAmount'}
     onFocus={event => {
      event.target.select();
     }}
     onChange={handleAmountChange}
    />
   </Box>
   <Box sx={ styles.itemInput}>
    <Typography variant="subtitle1" color="primary.dark" sx={styles.itemText} gutterBottom>
      Plazo
    </Typography>
    <Select
     id="selectedDays"
     value={simulation.days}
     onChange={handleDaysChange}
     input={<CustomSelectInput />}
     sx={styles.customSelect}
     IconComponent={props => (
      <i {...props} className={`material-icons ${props.className}`}>
       <KeyboardArrowDown/>
      </i>
     )}
     MenuProps={{
      PaperProps: styles.customSelectPaper,
     }}
    >
     { daysList && daysList.map((day, index) => (

      <MenuItem key={index} className={'selectedDays'} value={day.id}>{day.name}</MenuItem>
     ))}
    </Select>
   </Box>
   <Box sx={ styles.itemInput}>
    <Typography variant="subtitle1"  color="primary.dark" sx={styles.itemText} gutterBottom>
      Cliente
    </Typography>
    <Select
     id="selectedPayer"
     sx={styles.customSelect}
     value={simulation.payer}
     onChange={handlePayerChange}
     label="Pagador"
     input={<CustomSelectInput />}
     IconComponent={props => (
      <i {...props} className={`material-icons ${props.className}`}>
       <KeyboardArrowDown/>
      </i>
     )}
     MenuProps={{
      PaperProps: styles.customSelectPaper,
     }}
    >
     { Payers && Payers.map((payer, index) => (

      <MenuItem key={index} className={'selectedPayer'} value={payer.id}>{payer.name}</MenuItem>
     ))}
    </Select>
   </Box>
   <Box sx={ styles.itemLabel}>
    <Typography variant="subtitle1" gutterBottom sx={styles.labelText}>
      Costo por servicio
    </Typography>
    <Typography variant="subtitle1" gutterBottom >
     {financing.cost.formatted}
    </Typography>
   </Box>
   <Box sx={ styles.itemLabel}>
    <Typography variant="h6" gutterBottom sx={styles.labelText}>
      Monto a recibir
    </Typography>
    <Typography variant="h6" gutterBottom >
     {financing.receivedAmount.formatted}
    </Typography>
   </Box>
   <Box sx={ styles.itemFooter }>
    <Typography variant="caption">
      * Las tasas y plazos calculados son referenciales según las condiciones y aprobación de tu factura por el pagador.
    </Typography>
    <Box sx={{ paddingTop: 4 }}>
     <a href="https://financiamiento.cumplo.cl/registro" sid="btnRegisterSim"  style={{ textDecoration: 'none'}} className={'btnRegisterSim'}>
      <Button variant={"contrast"}>Regístrate</Button>
     </a>
    </Box>
   </Box>
  </Card>
 )
}

export { ProntoPago }
