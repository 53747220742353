import React from "react"
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Container } from "@mui/material";
import { Feature } from "src/templates"
import { ProntoPago } from "src/components/common/Simulator"


const SolutionBanner = ({title,subtitle, logo, description, buttonText, buttonAction, features, backgroundImage, form, solutionBackground}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  solutionBackground: {
   width: "100%",
   backgroundImage: `url(${backgroundImage})`,
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',
   backgroundPosition: 'center',
   borderRadius: '0% 0px 50% 0%',
  },
  solutionContent: {
   display: 'flex',
   flexDirection: 'column',
   minHeight: !md ? '45vw' : '90vw',
   padding: !md ? '7.5vw 24px !important' : '15vw 48px !important',
   rowGap: 4,
  },
  solutionSummary: {
   width: 150,
  },
  solutionDescription: {
   minHeight: !md ? 140 : 'auto',
   padding: "32px 0px",
  },
  solutionFeature: {
  },
  simulatorContainer:(mq)=>{
   return {
    background: `url(${backgroundImage})`,
    boxShadow:'0',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
   }
  },
  simulatorTitle:{
   fontWeight: 600,
  },
  titleSimulator:{
   padding: '80px 20px 20px',
   flexDirection: 'column',
   display: 'flex',
   rowGap: '25px',
   textAlign: "center"
  },
 }

 const lastIndex = title.lastIndexOf(" ");
 const titleWithoutLastWord = title.substring(0, lastIndex);
 const titleLastWord = title.split(" ").pop();;

 return (
  <>
   <Box item sx={ solutionBackground ? solutionBackground : styles.solutionBackground}>
    {!form?
     <Container sx={styles.solutionContent}>
      <Box sx={styles.solutionSummary}>
       {logo ? (
        <img src={logo} alt={title} style={{ width: 200 }}/>
       ) : (
        <>
         <Typography variant="h1" color='text.secondary' display="inline">{titleWithoutLastWord} </Typography>
         <Typography variant="h1" color='warning.main' display="inline">{titleLastWord}</Typography>
        </>
       )}
      </Box>
      <Box sx={styles.solutionFeature}>
       {features && (
        <Feature icon={features[2].icon} text={features[2].text} />
       )}
      </Box>
     </Container>
     :
     <Box sx={styles.simulatorContainer}>
      <Box sx={styles.titleSimulator}>
       <Typography sx={styles.simulatorTitle} variant="h2" color='text.secondary' display="inline">{subtitle}</Typography>
       <Typography sx={styles.simulatorSubtitle} variant="subtitle1" color='text.secondary'>{description}</Typography>
      </Box>
      <ProntoPago/>
      { lg && <Box sx={{pb: "70px"}} />}
     </Box>
    }
   </Box>
  </>
 )
}

export { SolutionBanner }
