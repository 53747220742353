import React from "react"
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Container } from "@mui/material";
import { Button, Feature } from "src/templates"
import { ProntoPago } from "src/components/common/Simulator"
const SolutionSlide = ({title, logo, subtitle, description, buttonText, buttonAction, features, backgroundImage, form}) => {

 const theme = useTheme();
 const xl = useMediaQuery(theme.breakpoints.down('xl'))

 const styles = {
  solutionBackground: {
   width: "100%",
   backgroundImage: `url(${backgroundImage})`,
   minHeight: 600,
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',
   backgroundPosition: 'center',
  },
  solutionContent: {
   display: 'flex',
   justifyContent: 'space-around',
   padding: '80px !important',
  },
  solutionSummary: {
   maxWidth: '500px',
   display: 'flex',
   justifyContent: 'center',
   flexDirection: 'column',
  },
  solutionDescription: {
   padding: "32px 0px 0px",
  },
  solutionFeatures: {
   maxWidth: '500px',
   height: 'auto',
   display: 'flex',
   justifyContent: 'space-between',
   flexDirection: 'column',
   minHeight: 470
  },
 }

 return (
  <Box item sx={styles.solutionBackground}>
   <Container sx={styles.solutionContent}>
    <Box sx={styles.solutionSummary}>
     <Typography variant="h2" color='text.secondary' display="inline" sx={{fontWeight: 600}}>{title}</Typography>
     <Box sx={styles.solutionDescription}>
      <Typography variant="subtitle1" color='text.secondary'>{description}</Typography>
     </Box>
     {buttonText ?
      <a href={buttonAction} style={{ textDecoration: 'none'}}>
       <Button variant={"contained"}>{buttonText}</Button>
      </a>
      : <span/>
     }

    </Box>
    <Box sx={styles.solutionFeatures}>
     {!form && features && features.map((feature, index) => (
      <Box
       key={index}
       sx={{
        alignSelf: index === 1 ? "end" : "start",
        visibility: ( index === 1 && xl ) ? 'hidden' : 'visible',
        marginBottom: index === 1 ? '-60px' : (index === 2) && '120px',
        paddingTop: index === 1 && '120px',
        paddingLeft: (index === 0 && !xl ) && 8,
       }}>
       <Feature key={index} icon={feature.icon} text={feature.text} />
      </Box>
     ))}
     {form === "ProntoPago" ?
      <Box>
       <ProntoPago/>
      </Box>
      :
      <div/>
     }


    </Box>
   </Container>
  </Box>

 )
}

export { SolutionSlide }
