const parseLocaleNumber = (stringNumber, props) =>{
 if (stringNumber === '$' || (typeof stringNumber === 'string' && stringNumber[0] !== '$')) return 0
 stringNumber = String(stringNumber)
 let symbol = Intl.NumberFormat(props.locale,{style:'currency',currency: props.currency}).format(1).replace(/\p{Number}/gu, '');

 let thousandSeparator = Intl.NumberFormat(props.locale).format(11111).replace(/\p{Number}/gu, '');

 let decimalSeparator = Intl.NumberFormat(props.locale).format(1.1).replace(/\p{Number}/gu, '');

 return parseFloat(stringNumber
  .replace(new RegExp('\\' + symbol, 'g'), '')
  .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
  .replace(new RegExp('\\' + decimalSeparator), '.')
 );
}

const currency = (value,props) => {
 if (typeof value === 'string') value = parseLocaleNumber(value,props)
 return {
  value:value,
  formatted:new Intl.NumberFormat(props.locale, { style: 'currency', currency: props.currency }).format(value)
 }
}
const currencies = {
 CLP: value => currency(value, { locale: "es-CL",currency:'CLP' }),
 PEN: value => currency(value, { locale: "es-PE",currency:'PEN' }),
 EUR: value => currency(value, { locale: "en-GB",currency:'EUR' }),
 MX:  value => currency(value, { locale: "es-MX",currency:'MXN' })
}

export {currencies}
