const Payers =  [
 {
  id: "13",
  name: "3ML",
  numero_documento_pagador: "995505800",
 },
 {
  id: "30",
  name: "Aasa",
  numero_documento_pagador: "79580160K",
 },
 {
  id: "31",
  name: "AES Andes",
  numero_documento_pagador: "942720009",
 },
 {
  id: "27",
  name: "Aguas Araucanía",
  numero_documento_pagador: "762156377",
 },
 {
  id: "4",
  name: "Australis",
  numero_documento_pagador: "760038857",
 },
 {
  id: "15",
  name: "Bluexpress",
  numero_documento_pagador: "969388405",
 },
 {
  id: "11",
  name: "Blumar",
  numero_documento_pagador: "808604000",
 },
 {
  id: "1",
  name: "Camanchaca",
  numero_documento_pagador: "937110006",
 },
 {
  id: "18",
  name: "Colbún",
  numero_documento_pagador: "965057609",
 },
 {
  id: "19",
  name: "DIN",
  numero_documento_pagador: "829823004",
 },
 {
  id: "5",
  name: "Dreams",
  numero_documento_pagador: "760335142",
 },
 {
  id: "3",
  name: "Edelpa",
  numero_documento_pagador: "899962001",
 },
 {
  id: "10",
  name: "EISA",
  numero_documento_pagador: "76081976K",
 },
 {
  id: "28",
  name: "Emb Andina",
  numero_documento_pagador: "618080005",
 },
 {
  id: "29",
  name: "Forus",
  numero_documento_pagador: "869632007",
 },
 {
  id: "2",
  name: "Granotop SpA",
  numero_documento_pagador: "995088304",
 },
 {
  id: "26",
  name: "Mall Plaza",
  numero_documento_pagador: "760170194",
 },
 {
  id: "25",
  name: "Masisa",
  numero_documento_pagador: "968026909",
 },
 {
  id: "6",
  name: "Moller",
  numero_documento_pagador: "92770000K",
 },
 {
  id: "14",
  name: "Pasa",
  numero_documento_pagador: "946270008",
 },
 {
  id: "7",
  name: "Pucobre",
  numero_documento_pagador: "965615601",
 },
 {
  id: "20",
  name: "Red Megacentro",
  numero_documento_pagador: "763770753",
 },
 {
  id: "8",
  name: "ICSK",
  numero_documento_pagador: "919150009",
 },
 {
  id: "9",
  name: "Socovesa",
  numero_documento_pagador: "948400006",
 },
 {
  id: "17",
  name: "Sodimac",
  numero_documento_pagador: "96792430K",
 },
 {
  id: "12",
  name: "Soprole",
  numero_documento_pagador: "761018124",
 },
 {
  id: "21",
  name: "SQM",
  numero_documento_pagador: "930070009",
 },
 {
  id: "16",
  name: "Tottus",
  numero_documento_pagador: "786272106",
 },
 {
  id: "22",
  name: "PUC",
  numero_documento_pagador: "816989000",
 },
 {
  id: "23",
  name: "UC CHRISTUS",
  numero_documento_pagador: "995402106",
 },
 {
  id: "24",
  name: "WOM",
  numero_documento_pagador: "789216908",
 },
]

export { Payers }
