import axios from "axios";
import { Payers } from "./Payers";
import { addfixedHeaders } from "../../../utils/addFixedHeaders";

async function simulate(sim) {
    const url =
        "https://operaciones.cumplo.com/v1/simulacion/CL/prontopago/CLP/cumplo";
    const key = "09G91RPyBT8wMMEGkR6G83PWcXxDxlfs7i3PmXsa";
    var days = new Date();
    days.setDate(new Date().getDate() + sim.days);

    let data = {
        monto_factura: sim.amount,
        porcentaje_financiamiento: 100,
        numero_facturas: 1,
        fecha_vencimiento_factura: days.toISOString(),
        numero_documento_pagador: Payers.find((item) => item.id === sim.payer)
            ?.numero_documento_pagador,
    };

    if (
        data.payerIdentifier === "" ||
        data.advanceDays === 0 ||
        data.amount <= 0
    ) {
        return {
            receivedAmount: 0,
            rate: 0,
            cost: 0,
        };
    }
    const res = await axios.post(url, data, {
        headers: {
            "x-api-key": key,
            ...addfixedHeaders(),
        },
    });
    return res.data;
}

export { simulate };
