import React from "react"
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Icon } from "src/templates"

const Feature = ({icon, text}) => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))

 const styles = {
  paper: {
   width: !md ? 325 : 250 ,
   padding: 2,
   borderRadius: 4,
  },
  benefitContent: {
   display: 'flex',
   columnGap: 2,
  },
 }

 return (
  <Paper sx={styles.paper}>
   <Box sx={styles.benefitContent}>
    <Box>
     <Icon height="40px" name={icon} color={theme.palette.secondary.main}/>
    </Box>
    <Box>
     <Typography>{text}</Typography>
    </Box>
   </Box>
  </Paper>
 )
}

export { Feature }